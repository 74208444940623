/**
 * @readonly
 * @enum {string}
 */
export const BULK_TRANSFER = {
  IDLE: "IDLE",
  STARTED: "STARTED",
  UPLOADING: "UPLOADING",
  UPLOADED: "UPLOADED",
  ABORT_UPLOADED: "ABORT_UPLOADED",
  UPLOAD_ERRORED: "UPLOAD_ERRORED",
  ABORT_AUTHORIZATION: "ABORT_AUTHORIZATION",
  CONFIRMATION: "CONFIRMATION",
  CONFIRMED: "CONFIRMED",
  AUTHORIZATION_ERROR: "AUTHORIZATION_ERROR",
  DISTRIBUTING: "DISTRIBUTING",
  DISTRIBUTED: "DISTRIBUTED",
  REJECTED: "REJECTED",
}

export const getStepNumberFromBulkTransferState = (uploadFileState) => {
  switch (uploadFileState) {
    case BULK_TRANSFER.STARTED:
    case BULK_TRANSFER.UPLOADING:
    case BULK_TRANSFER.UPLOADED:
    case BULK_TRANSFER.ABORT_UPLOADED:
    case BULK_TRANSFER.UPLOAD_ERRORED:
      return 0

    case BULK_TRANSFER.CONFIRMATION:
    case BULK_TRANSFER.CONFIRMED:
    case BULK_TRANSFER.DISTRIBUTING:
    case BULK_TRANSFER.DISTRIBUTED:
    case BULK_TRANSFER.REJECTED:
    case BULK_TRANSFER.ABORT_AUTHORIZATION:
    case BULK_TRANSFER.AUTHORIZATION_ERROR:
      return 1

    case BULK_TRANSFER.IDLE:
    default:
      return -1
  }
}

export const CSV_FILE_TABLE_COLUMNS = [
  "web_c_transfers_bulktransfermodal_previewstep_number_label",
  "web_c_transfers_bulktransfermodal_previewstep_name_label",
  "web_c_transfers_bulktransfermodal_previewstep_amount_label",
]
