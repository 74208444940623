import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { uploadTransactionAttachment } from "../../api/endpoints/transaction-attachments"
import {
  getBankTransferTransactionConfiguration,
  getDynamicPaymentTransactionConfiguration,
} from "../../api/endpoints/transaction-configuration"
import {
  getTransactionDetails,
  initiateTransaction,
  confirmTransaction,
  getBankTransferEnumeratedTypes,
  authorizeConfirmedTransaction,
  initiatedTransactionFeeCalculation,
} from "../../api/endpoints/transactions"
import {
  getTransactionHistory,
  getTransactionDetails as getTransferDetails,
} from "../../api/endpoints"
import { RECEIVE_PAYMENT } from "../../shared/constants/transfers/receive-payment"
import {
  authorizeBulkTransfer,
  bankTransferInitiation,
  bulkTransferUploader,
  getBulkTransferDetails,
  getBicCodeByIban,
} from "../../api/endpoints/transfers"
import {
  BANK_TRANSFER,
  MAP_BANK_TRANSFER_ERROR_CODE_TO_MESSAGE,
} from "../../shared/constants/transfers/bank-transfer"
import { CURRENCY_TYPE_IQD } from "../../shared/constants/bank-accounts"
import { onUploadProgressBuilder } from "../../lib/payroll/payroll"
import { BULK_TRANSFER } from "../../shared/constants/transfers/bulk-transfer"
import {
  FILE_RECOVERABLE_ERROR_CODES,
  MAP_PAYROLL_CSV_ERROR_CODE_TO_MESSAGE,
} from "../../shared/constants/payroll/file-salary-distribution"
import { GENERAL_ERROR } from "../../shared/constants/general"

const SLICE_NAME = "transfers"

export const fetchTransfers = createAsyncThunk(
  `${SLICE_NAME}/fetchTransfers`,
  async ({
    size,
    page,
    dateRange,
    operationTransactionTypes,
    operationStatuses,
    searchQuery,
    corporateTransfersOnly,
  }) => {
    const response = await getTransactionHistory({
      size,
      page,
      dateRange,
      operationTransactionTypes,
      operationStatuses,
      searchQuery,
      corporateTransfersOnly,
    })
    const { data, status, statusText } = response
    return { data, status, statusText }
  }
)

/**
 * @type {import("@reduxjs/toolkit").ActionCreatorWithPayload<{index: number, progress: number}>}
 */
export const receivePaymentUpdateUploadProgress = createAction(
  `${SLICE_NAME}/receivePaymentUpdateUploadProgress`
)

/**
 * @type {import("@reduxjs/toolkit").ActionCreatorWithPayload<{index: number, progress: number}>}
 */
export const bankTransferUpdateUploadProgress = createAction(
  `${SLICE_NAME}/bankTransferUpdateUploadProgress`
)

export const fetchDynamicPaymentTransactionConfiguration = createAsyncThunk(
  `${SLICE_NAME}/fetchDynamicPaymentTransactionConfiguration`,
  async () => {
    const response = await getDynamicPaymentTransactionConfiguration()
    const { data, status, statusText } = response
    return { data, status, statusText }
  }
)

export const fetchBankTransferTransactionConfiguration = createAsyncThunk(
  `${SLICE_NAME}/fetchBankTransferTransactionConfiguration`,
  async () => {
    const { data, status, statusText } =
      await getBankTransferTransactionConfiguration()

    return { data, status, statusText }
  }
)

export const fetchBankTransferEnumeratedTypes = createAsyncThunk(
  `${SLICE_NAME}/fetchBankTransferEnumeratedTypes`,
  async () => {
    const { data } = await getBankTransferEnumeratedTypes()
    return { data }
  },
  {
    condition: (_, { getState }) => {
      const { enumeratedTypes } = getState().transfers
      return enumeratedTypes === undefined
    },
  }
)

/**
 * @param {object} params
 * @param {FileList} params.attachments
 * @param {import("@reduxjs/toolkit").ActionCreator} params.updateProgressActionCreator
 */
export const uploadingTransactionAttachmentsPayloadCreator = async (
  { attachments, updateProgressActionCreator },
  thunkAPI
) => {
  try {
    const files = Array.from(attachments)
    const responses = await Promise.all(
      files.map((attachment, index) => {
        const formData = new FormData()
        formData.set("attachment", attachment, attachment.name)
        return uploadTransactionAttachment(formData, {
          /**
           *
           * @param {ProgressEvent} e
           */
          onUploadProgress: (e) => {
            if (!e.lengthComputable) return

            const progress = Math.round((e.loaded / e.total) * 100)
            thunkAPI.dispatch(updateProgressActionCreator({ index, progress }))
          },
          signal: thunkAPI.signal,
        })
      })
    )

    if (!responses.some((response) => response.status !== 201)) {
      return responses.map((response) => response.data.id)
    }

    return thunkAPI.rejectWithValue(
      responses.find((response) => response.status !== 201)
    )
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response)
  }
}

export const uploadingReceivePaymentTransactionAttachments = createAsyncThunk(
  `${SLICE_NAME}/uploadingReceivePaymentTransactionAttachments`,
  uploadingTransactionAttachmentsPayloadCreator
)

export const validateTransactionInitiationStatus = (status) => status === 201

export const paymentInitiation = createAsyncThunk(
  `${SLICE_NAME}/paymentInitiation`,
  async (_, thunkAPI) => {
    const {
      targetAccountId,
      monetaryValue,
      qrcodeExpiration,
      purpose,
      attachmentIds,
      brief: note,
    } = thunkAPI.getState().transfers.receivePayment.paymentConfig

    try {
      const response = await initiateTransaction(
        {
          targetAccountId,
          monetaryValue,
          period: qrcodeExpiration.id,
          purpose: purpose.id,
          attachments: attachmentIds.map((id) => ({ id })),
          note,
          type: "PAYMENT",
        },
        {
          validateStatus: validateTransactionInitiationStatus,
          signal: thunkAPI.signal,
        }
      )

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const fetchTransactionDetailsPayloadCreator = async (transactionId) => {
  const { data, status, statusText } = await getTransactionDetails(
    transactionId
  )
  return { data, status, statusText }
}

export const fetchReceivePaymentTransactionDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchReceivePaymentTransactionDetails`,
  fetchTransactionDetailsPayloadCreator
)

export const fetchBankTransferTransactionDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchBankTransferTransactionDetails`,
  fetchTransactionDetailsPayloadCreator
)

export const fetchBicCodeByIban = createAsyncThunk(
  `${SLICE_NAME}/fetchBicCodeByIban`,
  async (iban) => {
    const { data, status, statusText } = await getBicCodeByIban(iban)
    return { data, status, statusText }
  }
)

export const bankTransferUploadFile = createAsyncThunk(
  `${SLICE_NAME}/bankTransferUploadFile`,
  uploadingTransactionAttachmentsPayloadCreator
)

export const calculateInitiatedTransactionFee = createAsyncThunk(
  `${SLICE_NAME}/calculateBankTransferInitiatedTransactionFee`,
  async (transactionId) => {
    const { data, status, statusText } =
      await initiatedTransactionFeeCalculation(transactionId)
    return { data, status, statusText }
  }
)

export const initiateBankTransfer = createAsyncThunk(
  `${SLICE_NAME}/initiateBankTransfer`,
  async (_, thunkAPI) => {
    const {
      name,
      iban,
      brief,
      amount,
      country,
      attachmentIds,
      selectedPurpose,
      bicCode,
    } = thunkAPI.getState().transfers.bankTransfer

    try {
      const response = await bankTransferInitiation({
        monetaryValue: {
          amount,
          currency: CURRENCY_TYPE_IQD,
        },
        targetAccount: {
          iban,
          country,
          receiverName: name,
          bic: bicCode,
        },
        note: brief,
        attachments: attachmentIds?.map((id) => ({ id })),
        purpose: selectedPurpose,
      })

      if (response.status === 201) {
        thunkAPI.dispatch(
          fetchBankTransferTransactionDetails(response.data.transactionId)
        )
        thunkAPI.dispatch(
          calculateInitiatedTransactionFee(response.data.transactionId)
        )
      }

      const { data, status, statusText } = response
      return { data, status, statusText }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

// TODO make the transaction initiation, confirmation, authorizarion
// a payload creator to be reused in different slices
export const confirmBankTransferTransaction = createAsyncThunk(
  `${SLICE_NAME}/confirmBankTransferTransaction`,
  async (sourceAccountId, thunkAPI) => {
    const { transactionId } = thunkAPI.getState().transfers.bankTransfer

    try {
      const { data, status, statusText } = await confirmTransaction(
        transactionId,
        {
          sourceAccountId,
        }
      )
      return { data, status, statusText }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const authorizeConfirmedBankTransferTransactions = createAsyncThunk(
  `${SLICE_NAME}/authorizeConfirmedBankTransferTransaction`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken)
      throw new Error("web_c_general_notauthorized_error_text")

    const { transactionId } = thunkAPI.getState().transfers.bankTransfer

    try {
      const { data, status, statusText } = await authorizeConfirmedTransaction(
        transactionId,
        {
          headers: { Authorization: `Bearer ${elevatedToken}` },
        }
      )
      return { data, status, statusText }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data)
    }
  }
)

export const fetchTransferDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchTransferDetails`,
  async (transferId) => {
    const response = await getTransferDetails(transferId)

    const { data, status, statusText } = response
    return { data, status, statusText }
  }
)

export const receiveUploadBulkTransferProgress = createAction(
  `${SLICE_NAME}/receiveUploadBulkTransferProgress`
)

export const uploadBulkTransfer = createAsyncThunk(
  `${SLICE_NAME}/uploadBulkTransfer`,
  /**
   *
   * @param {object} params
   * @param {File} params.file
   * @returns
   */
  async ({ file }, { dispatch, rejectWithValue, signal }) => {
    try {
      const fileUploadRes = await bulkTransferUploader(
        { file },
        {
          onUploadProgress: onUploadProgressBuilder((precentage) => {
            dispatch(receiveUploadBulkTransferProgress(precentage))
          }),
          signal,
        }
      )

      const { data } = await getBulkTransferDetails(fileUploadRes.data.id)
      return { data, fileName: file.name }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchBulkTransferDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchBulkTransferDetails`,
  async (transactionId) => {
    const { data, status, statusText } = await getBulkTransferDetails(
      transactionId
    )
    return { data, status, statusText }
  }
)

export const bulkTransferAuthorization = createAsyncThunk(
  `${SLICE_NAME}/bulkTransferAuthorization`,
  async (_, thunkAPI) => {
    const { elevatedToken } = thunkAPI.getState().otac

    if (!elevatedToken) {
      throw thunkAPI.rejectWithValue({
        message: "web_c_general_notauthorized_error_text",
      })
    }

    const { id } = thunkAPI.getState().transfers.bulkTransfer.details

    try {
      await authorizeBulkTransfer(id, {
        headers: { Authorization: `Bearer ${elevatedToken}` },
      })
    } catch (error) {
      throw thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const initialState = {
  /**
   * transfers is an array of objects that holds the
   * informations for each transfers
   * @type {object[]}
   */
  transfers: undefined,

  /**
   * represents the loading state of the transfers
   * @type {boolean}
   */
  isTransfersLoading: false,

  /**
   * represents the error state of transfers if any
   * @type {object}
   */
  transfersError: undefined,

  /**
   * defined the current state of the table
   * and empowering the pagination of the page
   */
  isFirst: false,
  isLast: false,
  pageNumber: undefined,
  pageSize: undefined,
  totalElements: undefined,
  totalPages: undefined,

  /**
   * Whether the transfer details loading or not.
   */
  isTransferDetailsLoading: false,
  /**
   * The fetched transfer detail.
   */
  transferDetails: undefined,
  /**
   * Error that happened while loading the transfer detail detail.
   */
  transferDetailsLoadingError: undefined,

  /**
   * @typedef RECEIVE_PAYMENT_STATE
   * @property {string} state - State for tracking receive payment
   * @property {object} paymentConfig - configuration to create payment from
   *
   * @property {string} [paymentConfig.targetAccountId]
   * @property {import("../bankAccounts/bankAccounts-slice").MonetaryValue} [paymentConfig.monetaryValue]
   * @property {string} [paymentConfig.qrcodeExpiration]
   * @property {string} [paymentConfig.purpose]
   * @property {string} [paymentConfig.brief]
   * @property {string[]} paymentConfig.attachmentIds
   *
   * @property {object} generatedTransaction - generated transaction info
   * @property {string} [generatedTransaction.transactionId]
   * @property {string} [generatedTransaction.readableId]
   * @property {number} [generatedTransaction.createdAt]
   * @property {import("../../api/endpoints/transactions").TransactionDetails} [transactionDetails]
   * @property {boolean} isTransactionDetailsLoading
   * @property {boolean} isTransactionConfigLoading
   * @property {{
   *   min: import("../bankAccounts/bankAccounts-slice").MonetaryValue,
   *   max: import("../bankAccounts/bankAccounts-slice").MonetaryValue
   * }} possibleAmountRange
   * @property {number[]} uploadProgress
   * @property {import("@reduxjs/toolkit").SerializedError} error
   *
   */

  /**
   * @type {import("../../api/endpoints/transactions").BankTransferEnumeratedTypes=}
   */
  enumeratedTypes: undefined,

  /**
   * @type {boolean}
   */
  isEnumeratedTypesLoading: false,

  /**
   * @type {import("@reduxjs/toolkit").SerializedError=}
   */
  enumeratedTypesError: undefined,

  /**
   * @type {RECEIVE_PAYMENT_STATE}
   */
  receivePayment: {
    state: RECEIVE_PAYMENT.IDLE,

    paymentConfig: {
      targetAccountId: undefined,
      monetaryValue: undefined,
      qrcodeExpiration: undefined,
      purpose: undefined,
      brief: undefined,
      attachmentIds: [],
    },

    possibleAmountRange: undefined,

    isTransactionConfigLoading: false,

    generatedTransaction: {},

    transactionDetails: undefined,

    isTransactionDetailsLoading: false,

    uploadProgress: [],

    error: undefined,
  },

  bankTransfer: {
    /**
     * @type { BANK_TRANSFER }
     */
    state: BANK_TRANSFER.IDLE,

    /**
     * @type {string}
     */
    name: undefined,

    /**
     * @type {string}
     */
    iban: undefined,

    /**
     * @type {string}
     */
    country: undefined,

    /**
     * @type {string}
     */
    bicCode: undefined,

    /**
     * @type {boolean}
     */
    isBicCodeLoading: false,

    /**
     * @type {import("@reduxjs/toolkit").SerializedError}
     */
    bicCodeError: undefined,

    /**
     * @type {boolean}
     */
    isTransactionConfigLoading: false,

    /**
     * @type {{
     *   min: import("../bankAccounts/bankAccounts-slice").MonetaryValue,
     *   max: import("../bankAccounts/bankAccounts-slice").MonetaryValue
     * }}
     */
    possibleAmountRange: undefined,

    /**
     * @type {import("../bankAccounts/bankAccounts-slice").MonetaryValue}
     */
    balance: undefined,

    /**
     * @type {number}
     */
    amount: undefined,

    /**
     * @type {import("../../api/endpoints/transfers").BankTransferPurposeOptions}
     */
    selectedPurpose: undefined,

    /**
     * @type {string}
     */
    attachment: undefined,

    /**
     * @type {string[]}
     */
    attachmentIds: undefined,

    /**
     * @type {boolean}
     */
    isAttachmentsUploading: false,

    /**
     * @type {import("@reduxjs/toolkit").SerializedError}
     */
    attachmentIdsError: undefined,

    /**
     * @type {import("../../api/endpoints/transactions").TransactionFeeCalculation}
     */
    transactionAmount: undefined,

    /**
     * @type {import("@reduxjs/toolkit").SerializedError}
     */
    transactionAmountError: undefined,

    /**
     * @type {string}
     */
    transactionId: undefined,

    /**
     * @type {boolean}
     */
    isBankTransferInitiating: false,

    /**
     * @type {boolean}
     */
    isBankTransferConfirming: false,

    /**
     * @type {boolean}
     */
    isBankTransferConfirmed: false,

    /**
     * @type {import("../../api/endpoints/transactions").TransactionDetails}
     */
    transactionDetails: undefined,

    /**
     * @type {boolean}
     */
    isTransactionDetailsLoading: false,

    /**
     * @type {import("@reduxjs/toolkit").SerializedError}
     */
    transactionDetailsError: undefined,

    /**
     * @type {string}
     */
    brief: undefined,

    /**
     * @type {number[]}
     */
    uploadProgress: [],

    /**
     * @type {string}
     */
    error: undefined,
  },

  /**
   * it holds the bulk transfer by csv process
   * @type {object}
   */
  bulkTransfer: {
    /**
     * @type {BULK_TRANSFER}
     */
    state: BULK_TRANSFER.IDLE,
    /**
     * This state will hold the file upload precentage
     * @type {number}
     */
    uploadPrecentage: 0,
    /**
     * error happened during bulk transfer by csv
     *
     * @type {import("@reduxjs/toolkit").SerializedError}
     */
    error: undefined,

    /**
     * @type {import("../../api/endpoints/transactions").TransactionDetails}
     */
    details: undefined,

    /**
     * @type {boolean}
     */
    isDetailsLoading: false,
    /**
     * indicates whether the user confirmed the transaction
     *
     * @type {boolean}
     */
    isConfirmed: false,
  },
}

const transfersSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    // -------- Receive Payment -------- \\
    receivePaymentStarted: (state) => {
      state.receivePayment.state = RECEIVE_PAYMENT.STARTED
    },
    /**
     * @param {import("@reduxjs/toolkit").PayloadAction<{
     *         targetAccountId: string,
     *         monetaryValue: import("../bankAccounts/bankAccounts-slice").MonetaryValue,
     *         qrcodeExpiration: {id:string, text: string},
     *         purpose: {id:string, text: string},
     *         brief: string,
     * }>} action
     */
    receivePaymentInformationSet: (state, action) => {
      const {
        targetAccountId,
        monetaryValue,
        qrcodeExpiration,
        purpose,
        brief,
      } = action.payload
      state.receivePayment.state = RECEIVE_PAYMENT.INFORMATION_SET
      state.receivePayment.paymentConfig.targetAccountId = targetAccountId
      state.receivePayment.paymentConfig.monetaryValue = monetaryValue
      state.receivePayment.paymentConfig.qrcodeExpiration = qrcodeExpiration
      state.receivePayment.paymentConfig.purpose = purpose
      state.receivePayment.paymentConfig.brief = brief
    },
    receivePaymentAborting: (state) => {
      state.receivePayment.state = RECEIVE_PAYMENT.ABORTING
    },
    receivePaymentFinished: (state) => {
      state.receivePayment = initialState.receivePayment
    },
    // -------- Bank Transfer  -------- \\
    bankTransferStarted: (state) => {
      state.bankTransfer.state = BANK_TRANSFER.STARTED
    },
    /**
     * @param {Object} action
     * @param {Object} action.payload
     * @param {string} action.payload.name
     * @param {string} action.payload.iban
     * @param {string} action.payload.brief
     * @param {number} action.payload.amount
     * @param {string} action.payload.purpose
     * @param {string} action.payload.attachment
     * @param {string} action.payload.bicCode
     */
    bankTransferInformationSet: (state, action) => {
      state.bankTransfer.name = action.payload.name
      state.bankTransfer.iban = action.payload.iban
      state.bankTransfer.brief = action.payload.brief
      state.bankTransfer.amount = action.payload.amount
      state.bankTransfer.selectedPurpose = action.payload.purpose
      state.bankTransfer.attachment = action.payload.attachment
      state.bankTransfer.bicCode = action.payload.bicCode

      state.bankTransfer.state = BANK_TRANSFER.INFORMATION_SET
    },
    bankTransferResetAttachmentIds: (state) => {
      state.bankTransfer.attachmentIds = initialState.bankTransfer.attachmentIds
      state.bankTransfer.attachmentIdsError =
        initialState.bankTransfer.attachmentIdsError
    },
    bankTransferConfirmation: (state) => {
      state.bankTransfer.state = BANK_TRANSFER.CONFIRMATION
    },
    bankTransferAuthorizing: (state) => {
      state.bankTransfer.state = BANK_TRANSFER.AUTHORIZING
    },
    bankTransferAborting: (state) => {
      state.bankTransfer.state = BANK_TRANSFER.ABORTING
    },
    bankTransferRejected: (state) => {
      state.bankTransfer.state = BANK_TRANSFER.REJECTED
    },
    bankTransferFinished: (state) => {
      state.bankTransfer = initialState.bankTransfer
    },

    // -------- Bulk Transfer -------- \\
    bulkTransferStartedIdle: (state) => {
      state.bulkTransfer.state = BULK_TRANSFER.IDLE
    },
    bulkTransferStarted: (state) => {
      const startingState = {
        ...initialState.bulkTransfer,
        state: BULK_TRANSFER.STARTED,
      }
      state.bulkTransfer = startingState
    },
    bulkTransferUploaded: (state) => {
      state.bulkTransfer.state = BULK_TRANSFER.UPLOADED
    },
    bulkTransferConfirmation: (state) => {
      state.bulkTransfer.state = BULK_TRANSFER.CONFIRMATION
    },
    bulkTransferConfirmed: (state) => {
      state.bulkTransfer.state = BULK_TRANSFER.CONFIRMED
      state.bulkTransfer.isConfirmed = true
    },
    bulkTransferUploadAbortFile: (state) => {
      state.bulkTransfer.state = BULK_TRANSFER.ABORT_UPLOADED
    },
    bulkTransferFinished: (state) => {
      state.bulkTransfer = initialState.bulkTransfer
    },
    bulkTransferRejected: (state) => {
      state.bulkTransfer.state = BULK_TRANSFER.REJECTED
    },
    bulkTransferAbortAuthorization: (state) => {
      state.bulkTransfer.state = BULK_TRANSFER.ABORT_AUTHORIZATION
    },

    // -------- Reset Transfer Details -------- \\
    resetTransferDetails: (state) => {
      state.transferDetails = initialState.transferDetails
      state.transferDetailsLoadingError =
        initialState.transferDetailsLoadingError
    },
    // -------- Reset Dynamic Payment Error -------- \\
    resetDynamicPaymentConfigError: (state) => {
      state.receivePayment.error = undefined
    },
  },
  extraReducers: (builder) => {
    // -------- Fetch Transfers -------- \\
    builder.addCase(fetchTransfers.pending, (state) => {
      state.isTransfersLoading = true
    })

    builder.addCase(fetchTransfers.fulfilled, (state, action) => {
      state.isTransfersLoading = false
      state.transfersError = undefined
      state.transfers = action.payload.data.content
      state.isFirst = action.payload.data.first
      state.isLast = action.payload.data.last
      state.pageNumber = action.payload.data.pageNumber
      state.pageSize = action.payload.data.pageSize
      state.totalElements = action.payload.data.totalElements
      state.totalPages = action.payload.data.totalPages
    })

    builder.addCase(fetchTransfers.rejected, (state, action) => {
      state.isTransfersLoading = false
      state.transfersError = {
        name: action.error.name,
        message: action.error.message,
        code: action.error.code,
      }
    })

    // -------- Fetch Transfer Details -------- \\
    builder.addCase(fetchTransferDetails.pending, (state) => {
      state.isTransferDetailsLoading = true
    })

    builder.addCase(fetchTransferDetails.fulfilled, (state, action) => {
      state.isTransferDetailsLoading = false
      state.transferDetails = action.payload.data
    })

    builder.addCase(fetchTransferDetails.rejected, (state, action) => {
      state.isTransferDetailsLoading = false
      state.transferDetailsLoadingError = action.error
    })

    builder.addCase(fetchBankTransferEnumeratedTypes.pending, (state) => {
      state.isEnumeratedTypesLoading = true
    })

    builder.addCase(
      fetchBankTransferEnumeratedTypes.fulfilled,
      (state, action) => {
        state.isEnumeratedTypesLoading = false
        state.enumeratedTypes = action.payload.data
      }
    )

    builder.addCase(
      fetchBankTransferEnumeratedTypes.rejected,
      (state, action) => {
        state.isEnumeratedTypesLoading = false
        state.enumeratedTypesError = action.error
      }
    )

    // -------- receive payment -------- \\
    builder.addCase(
      fetchDynamicPaymentTransactionConfiguration.pending,
      (state) => {
        state.receivePayment.isTransactionConfigLoading = true
      }
    )
    builder.addCase(
      fetchDynamicPaymentTransactionConfiguration.fulfilled,
      (state, action) => {
        state.receivePayment.isTransactionConfigLoading = false
        const { amountRange } = action.payload.data

        state.receivePayment.possibleAmountRange = amountRange
      }
    )
    builder.addCase(
      fetchDynamicPaymentTransactionConfiguration.rejected,
      (state, action) => {
        state.receivePayment.state = RECEIVE_PAYMENT.ERRORED
        state.receivePayment.isTransactionConfigLoading = false
        state.receivePayment.error = {
          code: action.error.code,
          name: action.error.name,
          message: action.error.message,
        }
      }
    )

    builder.addCase(
      uploadingReceivePaymentTransactionAttachments.pending,
      (state, action) => {
        state.receivePayment.state = RECEIVE_PAYMENT.UPLOADING_ATTACHMENTS
        state.receivePayment.uploadProgress = Array.from({
          length: action.meta.arg.attachments.length,
        }).map(() => 0)
      }
    )

    builder.addCase(
      uploadingReceivePaymentTransactionAttachments.fulfilled,
      (state, action) => {
        state.receivePayment.state = RECEIVE_PAYMENT.UPLOADED_ATTACHMENTS
        state.receivePayment.paymentConfig.attachmentIds = action.payload
      }
    )
    builder.addCase(
      uploadingReceivePaymentTransactionAttachments.rejected,
      (state, action) => {
        if (action.meta.aborted) return

        state.receivePayment.error = { message: "web_c_general_error_massage" }
        state.receivePayment.state = RECEIVE_PAYMENT.ERRORED
      }
    )

    builder.addCase(receivePaymentUpdateUploadProgress, (state, action) => {
      const { index: targetIndex, progress: newProgress } = action.payload
      const currentProgress = state.receivePayment.uploadProgress

      state.receivePayment.uploadProgress = currentProgress.map(
        (progress, index) => (index !== targetIndex ? progress : newProgress)
      )
    })

    builder.addCase(paymentInitiation.pending, (state) => {
      state.receivePayment.state = RECEIVE_PAYMENT.CREATING
    })
    builder.addCase(paymentInitiation.fulfilled, (state, action) => {
      state.receivePayment.state = RECEIVE_PAYMENT.CREATED
      state.receivePayment.generatedTransaction = action.payload
    })
    builder.addCase(paymentInitiation.rejected, (state, action) => {
      if (action.meta.aborted) return

      const error = { title: action.error.name, message: action.error.message }

      if (
        action.meta.rejectedWithValue &&
        Array.isArray(action?.payload?.errors)
      ) {
        const [firstError] = action.payload.errors

        error.code = firstError.code
        error.title = firstError.title
        error.message = firstError.detail
      }

      state.receivePayment.state = RECEIVE_PAYMENT.ERRORED
      state.receivePayment.error = error
    })

    builder.addCase(fetchReceivePaymentTransactionDetails.pending, (state) => {
      state.receivePayment.isTransactionDetailsLoading = true
    })
    builder.addCase(
      fetchReceivePaymentTransactionDetails.fulfilled,
      (state, action) => {
        state.receivePayment.isTransactionDetailsLoading = false
        state.receivePayment.transactionDetails = action.payload.data
      }
    )
    builder.addCase(
      fetchReceivePaymentTransactionDetails.rejected,
      (state, action) => {
        state.receivePayment.isTransactionDetailsLoading = false
        state.receivePayment.error = action.error
        state.receivePayment.state = RECEIVE_PAYMENT.ERRORED
      }
    )

    // -------- Bank Transfer -------- \\
    builder.addCase(
      fetchBankTransferTransactionConfiguration.pending,
      (state) => {
        state.bankTransfer.isTransactionConfigLoading = true
      }
    )
    builder.addCase(
      fetchBankTransferTransactionConfiguration.fulfilled,
      (state, action) => {
        state.bankTransfer.isTransactionConfigLoading = false
        const { amountRange, balance } = action.payload.data

        state.bankTransfer.possibleAmountRange = amountRange
        state.bankTransfer.balance = balance
      }
    )
    builder.addCase(
      fetchBankTransferTransactionConfiguration.rejected,
      (state, action) => {
        state.bankTransfer.isTransactionConfigLoading = false
        state.bankTransfer.state = BANK_TRANSFER.ERRORED
        state.bankTransfer.error = {
          code: action.error.code,
          name: action.error.name,
          message: action.error.message,
        }
      }
    )

    builder.addCase(fetchBicCodeByIban.pending, (state) => {
      state.bankTransfer.isBicCodeLoading = true
    })
    builder.addCase(fetchBicCodeByIban.fulfilled, (state, action) => {
      state.bankTransfer.bicCodeError = undefined
      state.bankTransfer.isBicCodeLoading = false
      state.bankTransfer.bicCode = action.payload.data.bicCode
      state.bankTransfer.country = action.payload.data.accountCountry
    })
    builder.addCase(fetchBicCodeByIban.rejected, (state, action) => {
      state.bankTransfer.isBicCodeLoading = false
      state.bankTransfer.bicCode = undefined
      state.bankTransfer.bicCodeError = action.error
    })

    builder.addCase(bankTransferUploadFile.pending, (state) => {
      state.bankTransfer.isAttachmentsUploading = true
    })
    builder.addCase(bankTransferUploadFile.fulfilled, (state, action) => {
      state.bankTransfer.isAttachmentsUploading = false
      state.bankTransfer.attachmentIdsError = undefined
      state.bankTransfer.attachmentIds = action.payload
    })
    builder.addCase(bankTransferUploadFile.rejected, (state, action) => {
      state.bankTransfer.isAttachmentsUploading = false
      state.bankTransfer.attachmentIdsError = action.error
    })

    builder.addCase(bankTransferUpdateUploadProgress, (state, action) => {
      const { index, progress } = action.payload
      state.bankTransfer.uploadProgress[index] = progress
    })

    builder.addCase(initiateBankTransfer.pending, (state) => {
      state.bankTransfer.isBankTransferInitiating = true
    })
    builder.addCase(initiateBankTransfer.fulfilled, (state, action) => {
      state.bankTransfer.isBankTransferInitiating = false
      state.bankTransfer.state = BANK_TRANSFER.CONFIRMATION
      state.bankTransfer.transactionId = action.payload.data.transactionId
    })
    builder.addCase(initiateBankTransfer.rejected, (state, action) => {
      state.bankTransfer.isBankTransferInitiating = false
      state.bankTransfer.state = BANK_TRANSFER.ERRORED
      const error = { message: "web_c_general_error_massage" }

      if (Array.isArray(action.payload?.errors)) {
        const { detail } = action.payload.errors[0]
        error.message = detail
      }

      state.bankTransfer.error = error
    })

    builder.addCase(fetchBankTransferTransactionDetails.pending, (state) => {
      state.bankTransfer.isTransactionDetailsLoading = true
    })
    builder.addCase(
      fetchBankTransferTransactionDetails.fulfilled,
      (state, action) => {
        state.bankTransfer.isTransactionDetailsLoading = false
        state.bankTransfer.transactionDetails = action.payload.data
        state.bankTransfer.transactionAmount = action.payload.data.monetaryValue
      }
    )
    builder.addCase(
      fetchBankTransferTransactionDetails.rejected,
      (state, action) => {
        state.bankTransfer.isTransactionDetailsLoading = false
        state.bankTransfer.state = BANK_TRANSFER.ERRORED
        state.bankTransfer.transactionDetailsError = action.error
      }
    )

    builder.addCase(confirmBankTransferTransaction.pending, (state) => {
      state.bankTransfer.isBankTransferConfirming = true
    })
    builder.addCase(confirmBankTransferTransaction.fulfilled, (state) => {
      state.bankTransfer.state = BANK_TRANSFER.AUTHORIZING
      state.bankTransfer.isBankTransferConfirming = false
      state.bankTransfer.isBankTransferConfirmed = true
    })
    builder.addCase(
      confirmBankTransferTransaction.rejected,
      (state, action) => {
        state.bankTransfer.isBankTransferConfirming = false
        state.bankTransfer.isBankTransferConfirmed = false
        state.bankTransfer.state = BANK_TRANSFER.ERRORED

        const error = { message: "web_c_general_error_massage" }

        if (Array.isArray(action.payload?.errors)) {
          const { detail, code } = action.payload.errors[0]
          error.message = detail
          const errorMessage = MAP_BANK_TRANSFER_ERROR_CODE_TO_MESSAGE[code]

          if (errorMessage) {
            error.message = errorMessage
          }
        }

        state.bankTransfer.error = error
      }
    )

    builder.addCase(
      authorizeConfirmedBankTransferTransactions.fulfilled,
      (state) => {
        state.bankTransfer.state = BANK_TRANSFER.TRANSFERED
      }
    )
    builder.addCase(
      authorizeConfirmedBankTransferTransactions.rejected,
      (state, action) => {
        state.bankTransfer.state = BANK_TRANSFER.ERRORED

        const error = { message: "web_c_general_error_massage" }

        if (Array.isArray(action.payload?.errors)) {
          const { detail, code } = action.payload.errors[0]
          error.message = detail
          const errorMessage = MAP_BANK_TRANSFER_ERROR_CODE_TO_MESSAGE[code]

          if (errorMessage) {
            error.message = errorMessage
          }
        } else if (action.error.message !== "Rejected") {
          error.message = action.error.message
        }

        state.bankTransfer.error = error
      }
    )

    builder.addCase(
      calculateInitiatedTransactionFee.fulfilled,
      (state, action) => {
        state.bankTransfer.transactionAmount =
          action.payload.data.transactionAmount
      }
    )
    builder.addCase(
      calculateInitiatedTransactionFee.rejected,
      (state, action) => {
        state.bankTransfer.transactionAmountError = action.error
      }
    )

    // -------- Bulk Transfer -------- \\
    builder.addCase(fetchBulkTransferDetails.pending, (state) => {
      state.bulkTransfer.isDetailsLoading = true
    })
    builder.addCase(fetchBulkTransferDetails.fulfilled, (state, action) => {
      state.bulkTransfer.isDetailsLoading = false
      state.bulkTransfer.details = action.payload.data
    })
    builder.addCase(fetchBulkTransferDetails.rejected, (state, action) => {
      state.bulkTransfer.isDetailsLoading = false
      state.bulkTransfer.state = BULK_TRANSFER.ERRORED
      state.bulkTransfer.error = action.error
    })
    builder.addCase(uploadBulkTransfer.pending, (state) => {
      state.bulkTransfer.state = BULK_TRANSFER.UPLOADING
    })
    builder.addCase(uploadBulkTransfer.fulfilled, (state, action) => {
      state.bulkTransfer.state = BULK_TRANSFER.UPLOADED
      state.bulkTransfer.uploadPrecentage = 0
      state.bulkTransfer.details = {
        createdAt: action.payload.data.createdAt,
        transfersCount: action.payload.data.transfersCount,
        id: action.payload.data.id,
        payees: action.payload.data.payees,
        status: action.payload.data.status,
        totalAmount: action.payload.data.totalAmount,
        type: action.payload.data.type,
        fileName: action.payload.fileName,
      }
    })
    builder.addCase(uploadBulkTransfer.rejected, (state, action) => {
      state.bulkTransfer.uploadPrecentage = 0

      if (action.meta.aborted) {
        state.bulkTransfer.error = undefined
        state.bulkTransfer.state = BULK_TRANSFER.STARTED
      } else {
        let preparedError = {
          code: GENERAL_ERROR,
          message: "web_c_general_error_massage",
        }

        if (action.meta.rejectedWithValue) {
          if (action.payload?.errors) {
            const [firstError] = action.payload.errors
            // FIXME the error detail should always be returned
            // when Backend adds the detail this should be removed
            // and then -> error={...firstError,recoverable:---}
            const errorMessage =
              MAP_PAYROLL_CSV_ERROR_CODE_TO_MESSAGE[firstError.code] ===
              undefined
                ? "web_c_general_error_massage"
                : MAP_PAYROLL_CSV_ERROR_CODE_TO_MESSAGE[firstError.code]

            preparedError = {
              ...firstError,
              detail:
                firstError.detail === "" ? errorMessage : firstError.detail,
              recoverable: FILE_RECOVERABLE_ERROR_CODES.includes(
                firstError.code
              ),
            }
          }
        }

        state.bulkTransfer.error = preparedError
        state.bulkTransfer.state = BULK_TRANSFER.UPLOAD_ERRORED
      }
    })

    builder.addCase(receiveUploadBulkTransferProgress, (state, action) => {
      state.bankTransfer.uploadPrecentage = action.payload
    })

    // -------- Bulk Transfer Authorization -------- \\
    builder.addCase(bulkTransferAuthorization.pending, (state) => {
      state.bulkTransfer.state = BULK_TRANSFER.DISTRIBUTING
    })

    builder.addCase(bulkTransferAuthorization.fulfilled, (state) => {
      state.bulkTransfer.state = BULK_TRANSFER.DISTRIBUTED
    })

    builder.addCase(bulkTransferAuthorization.rejected, (state, action) => {
      let preparedError = {
        code: GENERAL_ERROR,
        message: "web_c_general_error_massage",
      }

      if (action.meta.rejectedWithValue) {
        if (action.payload?.errors) {
          const [firstError] = action.payload.errors
          preparedError = {
            ...firstError,
            recoverable: FILE_RECOVERABLE_ERROR_CODES.includes(firstError.code),
          }
        }
      }

      state.bulkTransfer.error = preparedError
      state.bulkTransfer.isConfirmed = false
      state.bulkTransfer.state = BULK_TRANSFER.AUTHORIZATION_ERROR
    })
  },
})

export const {
  // -------- receive payment -------- \\
  receivePaymentInformationSet,
  receivePaymentStarted,
  receivePaymentAborting,
  receivePaymentFinished,
  // -------- Bank Transfer -------- \\
  bankTransferStarted,
  bankTransferAborting,
  bankTransferRejected,
  bankTransferFinished,
  bankTransferAuthorizing,
  bankTransferConfirmation,
  bankTransferInformationSet,
  bankTransferResetAttachmentIds,
  // -------- Bulk Transfer -------- \\
  bulkTransferStartedIdle,
  bulkTransferStarted,
  bulkTransferUploaded,
  bulkTransferFinished,
  bulkTransferUploadAbortFile,
  bulkTransferConfirmation,
  bulkTransferConfirmed,
  bulkTransferRejected,
  bulkTransferAbortAuthorization,
  // -------- Reset Transfer -------- \\
  resetTransferDetails,
  // -------- Reset Dynamic Payment Error -------- \\
  resetDynamicPaymentConfigError,
} = transfersSlice.actions

export default transfersSlice.reducer
