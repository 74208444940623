import dayjs from "dayjs"

import { thousandFormatter } from "../../lib/general"
import {
  MAP_TRANSACTION_TYPE_TO_LOKALISE_KEY,
  TRANSACTION_TYPE_P2P,
} from "../../shared/constants/transactions"

/**
 *
 * @param {object[]} [data=[]]
 * @param {string} data.id
 * @param {object} data.counterparty
 * @param {string} data.counterparty.name
 * @param {string} data.counterparty.phoneNumber
 * @param {string} data.counterparty.iban
 * @param {object} data.transaction
 * @param {string} data.transaction.type
 * @param {number} data.timestamp
 * @param {import("../bankAccounts/bankAccounts-slice").MonetaryValue} data.monetaryValue
 * @param {object[]} [accountsList=[]]
 * @param {string} accountsList.id
 * @param {string} accountsList.text
 * @param {boolean} accountsList.isMainAccount
 * @param {boolean} accountsList.isSubaccount
 * @param {boolean} isRTL
 * @returns
 */
export const transformAggregatedTransactionsData = (
  data,
  accountsList,
  isRTL
) => {
  if (!Array.isArray(data)) return []

  const mainAccountText = accountsList?.find(
    ({ isMainAccount }) => isMainAccount === true
  )?.text

  return data.map((item) => {
    let formattedAmount = thousandFormatter(item?.monetaryValue?.amount, isRTL)

    if (item?.monetaryValue?.amount > 0) {
      formattedAmount = `+${formattedAmount}`
    }

    let accountText = item?.party?.name

    const isMainAccountCurrencyType = accountsList?.some(
      ({ id }) => item?.party?.accountId === id
    )

    if (!isMainAccountCurrencyType) {
      accountText = `${mainAccountText} / ${item?.party?.name}`
    }

    return {
      id: item.id,
      name: item?.counterparty?.name,
      ibanOrPhoneNo:
        item?.transaction?.type === TRANSACTION_TYPE_P2P
          ? item?.counterparty?.phoneNumber
          : item?.counterparty?.iban,
      typeLokaliseKey:
        MAP_TRANSACTION_TYPE_TO_LOKALISE_KEY[item?.transaction?.type],
      account: accountText,
      amount: formattedAmount,
      currency: item?.monetaryValue?.currency,
      date: dayjs(item?.timestamp).format("MM/DD/YYYY, hh:mm:ss A"),
    }
  })
}
