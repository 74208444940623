import { BALANCE_HISTORY_OPTION_CUSTOM } from "../shared/constants/analytics/dashboard"

export const buildTransactionHistoryParams = ({
  page,
  size,
  selectedAccountIds,
  type,
  dateRange,
  searchQuery,
  operationTransactionTypes,
  operationStatuses,
  corporateTransfersOnly,
}) => {
  const params = new URLSearchParams()
  params.set("page", page)
  params.set("size", size)

  if (selectedAccountIds?.length > 0) {
    selectedAccountIds.forEach((selectedAccount) => {
      params.append("accountId", selectedAccount)
    })
  }

  if (type) {
    params.set("type", type)
  }

  if (dateRange && !dateRange.initial) {
    params.set("startDate", dateRange.startDate.valueOf())
    params.set("endDate", dateRange.endDate.valueOf())
  }

  if (searchQuery && searchQuery.trim().length > 0) {
    params.set("searchQuery", searchQuery.trim())
  }

  if (Array.isArray(operationTransactionTypes)) {
    operationTransactionTypes.forEach((operationTransactionType) => {
      params.append("operationTransactionType", operationTransactionType)
    })
  }

  if (Array.isArray(operationStatuses)) {
    operationStatuses.forEach((operationStatus) => {
      params.append("operationStatus", operationStatus)
    })
  }

  if (corporateTransfersOnly) {
    params.append("corporateTransfersOnly", corporateTransfersOnly)
  }

  return params
}

export const buildAggregativeBalanceHistoryParams = ({
  accountIds,
  currencies,
  startDate,
  endDate,
  timeFrame = BALANCE_HISTORY_OPTION_CUSTOM,
}) => {
  const params = new URLSearchParams()
  accountIds.forEach((accountId) => {
    params.append("accountId", accountId)
  })
  currencies.forEach((currency) => {
    params.append("currency", currency)
  })
  params.append("timeFrame", timeFrame)
  params.append("startDate", startDate.valueOf())
  params.append("endDate", endDate.valueOf())
  return params
}

export const getEmployeesParamsCreator = ({
  page,
  size,
  teamId,
  searchQuery,
  teamlessOnly,
}) => {
  const params = new URLSearchParams()
  params.set("page", page)
  params.set("size", size)
  if (teamId) params.set("teamId", teamId)
  if (searchQuery) params.set("searchQuery", searchQuery)
  if (teamlessOnly) params.set("teamlessOnly", "true")
  return params
}

export const buildTeamsParams = ({ page, size, searchQuery }) => {
  const params = new URLSearchParams()

  params.set("page", page)
  params.set("size", size)

  if (searchQuery && searchQuery.trim().length > 0) {
    params.set("searchQuery", searchQuery.trim())
  }

  return params
}

export const httpStatusValidators = {
  ACCEPTED: (status) => status === 202,
  CREATED: (status) => status === 201,
  OK: (status) => status === 200,
}

export const buildPendingApprovalsParams = ({
  page,
  size,
  type,
  dateRange,
  searchQuery,
}) => {
  const params = new URLSearchParams()
  params.set("page", page)
  params.set("size", size)
  if (searchQuery) params.set("searchQuery", searchQuery)
  if (type) params.set("type", type)
  if (dateRange && !dateRange.initial) {
    params.set("startDate", dateRange.startDate.valueOf())
    params.set("endDate", dateRange.endDate.valueOf())
  }

  return params
}

export const buildUsersParams = ({ page, size, withBlocked, searchQuery }) => {
  const params = new URLSearchParams()
  params.set("withBlocked", withBlocked)
  params.set("page", page)
  params.set("size", size)
  if (searchQuery) params.set("searchQuery", searchQuery)

  return params
}
