import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import {
  getTransactionHistory,
  getTransactionDetails,
} from "../../api/endpoints"
import { transformAggregatedTransactionsData } from "./transactions-helpers"

const SLICE_NAME = "transactions"

export const fetchTransactionHistoryPayloadCreator = async ({
  page,
  size,
  selectedAccountIds,
  type,
  dateRange,
  searchQuery,
}) => {
  const res = await getTransactionHistory({
    page,
    size,
    selectedAccountIds,
    type,
    dateRange,
    searchQuery,
  })
  const { data, status, statusText } = res
  return { data, status, statusText }
}

export const fetchTransactionHistory = createAsyncThunk(
  `${SLICE_NAME}/fetchTransactionHistory`,
  fetchTransactionHistoryPayloadCreator
)

/**
 *
 * @param {string} transactionId
 * @returns
 */
export const fetchTransactionDetailsPayloadCreator = async (
  transactionId,
  thunkApi
) => {
  const res = await getTransactionDetails(transactionId, {
    signal: thunkApi.signal,
  })
  const { data, status, statusText } = res
  return { data, status, statusText }
}

export const fetchTransactionDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchTransactionDetails`,
  fetchTransactionDetailsPayloadCreator
)

const initialState = {
  /**
   * indicates whether last transactions loading or not.
   */
  isTransactionsLoading: false,
  /**
   * list of the latest fetched transactions.
   */
  transactionsList: undefined,
  isFirst: false,
  isLast: false,
  pageNumber: undefined,
  pageSize: undefined,
  totalElements: undefined,
  totalPages: undefined,

  transactionsError: undefined,
  /**
   * Whether the transaction details loading or not.
   */
  isTransactionDetailsLoading: false,
  /**
   * The detail of one transaction.
   * @type { import("../../api/endpoints").GetTransactionDetailsResponse }
   */
  transactionDetails: undefined,
  /**
   * Error that happened while loading transaction details.
   */
  transactionDetailsLoadingError: undefined,
}

const transactionsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    dismissTransactionDetails: (state) => {
      state.isTransactionDetailsLoading = false
      state.transactionDetails = undefined
      state.transactionDetailsLoadingError = undefined
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransactionHistory.pending, (state) => {
      state.isTransactionsLoading = true
    })

    builder.addCase(fetchTransactionHistory.fulfilled, (state, action) => {
      state.isTransactionsLoading = false
      state.transactionsList = transformAggregatedTransactionsData(
        action?.payload?.data?.content,
        action.meta.arg.accountsList,
        action.meta.arg.isRTL
      )
      state.isFirst = action?.payload?.data?.first
      state.isLast = action?.payload?.data?.last
      state.pageNumber = action?.payload?.data?.pageNumber
      state.pageSize = action?.payload?.data?.pageSize
      state.totalElements = action?.payload?.data?.totalElements
      state.totalPages = action?.payload?.data?.totalPages
    })
    builder.addCase(fetchTransactionHistory.rejected, (state) => {
      state.isTransactionsLoading = false
      // FIXME: the {rejected} case wasn't handled before so this implementation is temporary until the release is done
      state.transactionsError = {
        message: "web_c_general_error_massage",
      }
    })

    builder.addCase(fetchTransactionDetails.pending, (state) => {
      state.isTransactionDetailsLoading = true
    })

    builder.addCase(fetchTransactionDetails.fulfilled, (state, action) => {
      state.isTransactionDetailsLoading = false
      state.transactionDetails = action.payload.data
    })

    builder.addCase(fetchTransactionDetails.rejected, (state, action) => {
      state.isTransactionDetailsLoading = false
      state.transactionDetailsLoadingError = action.error
    })
  },
})

export const { dismissTransactionDetails } = transactionsSlice.actions

export default transactionsSlice.reducer
