import AxiosInstance from "../axios"
import { httpStatusValidators } from "../endpointsHelper"

/**
 * @typedef GetBicCodeByIban
 * @property {string} bicCode
 * @property {string} accountCountry
 * @property {string} bankName
 *
 * @param {string} iban
 *
 * @returns { Promise<import("axios").AxiosResponse<GetBicCodeByIban>> }
 */
export const getBicCodeByIban = (iban) =>
  AxiosInstance.get(`/v1/transfers/iban/${iban}/account-data`)

/**
 * @typedef { "RENT" | "OTHER" | "PERFORMED_TASK" | "FAMILY_SUPPORT" | "TRAVEL_EXPENSES" | "EDUCATION_OR_SCHOOL_FEE" | "EMERGENCY_OR_MEDICAL_AID" } BankTransferPurposeOptions
 *
 * @typedef {object} TargetAccount
 * @property {string} iban
 * @property {string} receiverName
 * @property {string} country
 *
 * @typedef {object} InitiateBankTrasnferData
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} monetaryValue
 * @property {string} none
 * @property {TargetAccount} targetAccount
 * @property {BankTransferPurposeOptions} purpose
 * @property {string[]} attachmentIds
 *
 * @param {InitiateBankTrasnferData} data
 * @returns { Promise<import("axios").AxiosResponse<{transactionId: string}>> }
 */
export const bankTransferInitiation = (data) =>
  AxiosInstance.post("/v2/transfers", data)

/**
 *
 * @param {object} params
 * @param {object} params.file
 * @param {import("axios").AxiosRequestConfig} config
 *
 * @returns { Promise<import("axios").AxiosResponse<undefined>> }
 */
export const bulkTransferUploader = ({ file }, config) => {
  const formData = new FormData()
  formData.append("file", file)

  return AxiosInstance.post(
    `/v1/corporate-users/me/bulk-transfers/file`,
    formData,
    config
  )
}

/**
 * @typedef { ("DRAFT" | "AUTHORIZED" | "PROCESSING" | "PROCESSED") } BulkTransferStatus
 *
 * @typedef Payee
 * @property {string} name
 * @property {string} type
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} amount
 * @property {string[]} payees
 *
 * @typedef BulkTransferDetails
 * @property {string} id
 * @property {import("./roles").FeaturesNames} type
 * @property {number} createdAt
 * @property {number} transfersCount
 * @property {Payee[]} payees
 * @property {import("../../features/bankAccounts/bankAccounts-slice").MonetaryValue} totalAmount
 * @property {BulkTransferStatus} status
 * @property {{name: string, type: string, payees: TotalAmount[]}[]} payees
 *
 * @typedef {BulkTransferDetails} BulkTransferDetailsResponse
 *
 * @param {string} bulkTransferId
 * @returns {Promise<import("axios").AxiosResponse<BulkTransferDetailsResponse>>}
 */
export const getBulkTransferDetails = (bulkTransferId) =>
  AxiosInstance.get(`/v1/corporate-users/me/bulk-transfers/${bulkTransferId}`)

export const getBulkTransferCsvSample = () =>
  AxiosInstance.get("/v1/corporate-users/me/bulk-transfers/sample/csv", {
    responseType: "blob",
  })

/**
 * @param {string} bulkTransferId
 * @param { import("axios").AxiosRequestConfig } config
 *
 * @returns { Promise<import("axios").AxiosResponse<undefined>> }
 */
export const authorizeBulkTransfer = (bulkTransferId, config) =>
  AxiosInstance.post(
    `/v1/corporate-users/me/bulk-transfers/${bulkTransferId}/authorize`,
    null,
    { ...config, validateStatus: httpStatusValidators.ACCEPTED }
  )
